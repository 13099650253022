import { css } from '@emotion/react'
import { appColor } from './app-colors'
import { notojp } from './app-typography'

export const _appStyled = css`
  body {
    padding: 0;
    background-color: #fdfdfd;
    font-style: normal;
    font-weight: normal;
    min-height: 100dvh;
    overflow-x: hidden;

    max-width: 768px;
    margin: 0 auto;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: inherit;
    }
  }

  & .slick-slider {
    max-width: 100%;
  }

  & .MuiPaper-root.MuiMenu-paper {
    max-height: calc(100% - 400px) !important
  }

  & .MuiAlert-root {
    padding: 12px 16px !important;
    & .MuiAlert-message {
      font-family: ${notojp.style.fontFamily};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      & .MuiAlertTitle-root {
        font-family: ${notojp.style.fontFamily};
        font-weight: 500;
        font-size: 16px;
    }
    }
  }

  //  react-toastify-css
  .Toastify__toast-container {
    .Toastify__toast {
      min-height: 48px;
    }
    .Toastify__toast-body {
      font-family: ${notojp.style.fontFamily};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: ${appColor.Text.white};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--default {
    background: ${appColor.Text.secondary};
  }
  .Toastify__toast-theme--colored.Toastify__toast--info {
    background: ${appColor.Service.blue};
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: ${appColor.Service.green};
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background: ${appColor.Service.orange};
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background: ${appColor.Service.red};
  }
  .Toastify__progress-bar {
    background: ${appColor.App.white};
  }
  .Toastify__close-button > svg {
    fill: ${appColor.App.white};
  }
`
