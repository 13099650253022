import { APIPaths } from '@/apis/api-config'

import BaseApi from '../base-api'

export class appConversationApi extends BaseApi {
  async appGetConversationMeList(
    queryParams?: APIPaths['/app/conversations/me/list']['get']['parameters']['query'],
  ): Promise<APIPaths['/app/conversations/me/list']['get']['responses']['200']['content']['application/json']> {
    return (await this.get('/app/conversations/me/list', { params: queryParams })).data
  }
  async appGetConversationById(
    conversation_id: APIPaths['/app/conversations/{conversation_id}']['get']['parameters']['path']['conversation_id'],
  ): Promise<
    APIPaths['/app/conversations/{conversation_id}']['get']['responses']['200']['content']['application/json']
  > {
    return (await this.get(`/app/conversations/${conversation_id}`)).data
  }
  async appPutTargetUserIdSend(
    target_user_id: APIPaths['/app/conversations/{target_user_id}/send']['put']['parameters']['path']['target_user_id'],
  ): Promise<
    APIPaths['/app/conversations/{target_user_id}/send']['put']['responses']['200']['content']['application/json']
  > {
    return (await this.put(`/app/conversations/${target_user_id}/send`)).data
  }
  async appDeleteConversationCancel(
    conversation_id: APIPaths['/app/conversations/{conversation_id}/cancel']['delete']['parameters']['path']['conversation_id'],
  ): Promise<
    APIPaths['/app/conversations/{conversation_id}/cancel']['delete']['responses']['200']['content']['application/json']
  > {
    return (await this.delete(`/app/conversations/${conversation_id}/cancel`)).data
  }
  async appPutConversationApprove(
    conversation_id: APIPaths['/app/conversations/{conversation_id}/approve']['put']['parameters']['path']['conversation_id'],
  ): Promise<
    APIPaths['/app/conversations/{conversation_id}/approve']['put']['responses']['200']['content']['application/json']
  > {
    return (await this.put(`/app/conversations/${conversation_id}/approve`)).data
  }
  async appDeleteConversationReject(
    conversation_id: APIPaths['/app/conversations/{conversation_id}/reject']['delete']['parameters']['path']['conversation_id'],
  ): Promise<
    APIPaths['/app/conversations/{conversation_id}/reject']['delete']['responses']['200']['content']['application/json']
  > {
    return (await this.delete(`/app/conversations/${conversation_id}/reject`)).data
  }
  async appPutConversationSchedule(
    conversation_id: APIPaths['/app/conversations/{conversation_id}/schedule']['put']['parameters']['path']['conversation_id'],
    requestBody: APIPaths['/app/conversations/{conversation_id}/schedule']['put']['requestBody']['content']['application/json'],
  ): Promise<
    APIPaths['/app/conversations/{conversation_id}/schedule']['put']['responses']['200']['content']['application/json']
  > {
    return (await this.put(`/app/conversations/${conversation_id}/schedule`, requestBody)).data
  }
  async appPutConversationScheduleEdit(
    conversation_id: APIPaths['/app/conversations/{conversation_id}/schedule-edit']['put']['parameters']['path']['conversation_id'],
    requestBody: APIPaths['/app/conversations/{conversation_id}/schedule-edit']['put']['requestBody']['content']['application/json'],
  ): Promise<
    APIPaths['/app/conversations/{conversation_id}/schedule-edit']['put']['responses']['200']['content']['application/json']
  > {
    return (await this.put(`/app/conversations/${conversation_id}/schedule-edit`, requestBody)).data
  }

  async appPutConversationScheduleCancel(
    conversation_id: APIPaths['/app/conversations/{conversation_id}/schedule-cancel']['put']['parameters']['path']['conversation_id'],
  ): Promise<
    APIPaths['/app/conversations/{conversation_id}/schedule-cancel']['put']['responses']['200']['content']['application/json']
  > {
    return (await this.put(`/app/conversations/${conversation_id}/schedule-cancel`)).data
  }
  async appPutConversationScheduleAccept(
    conversation_id: APIPaths['/app/conversations/{conversation_id}/schedule-accept/{decide_schedule_id}']['put']['parameters']['path']['conversation_id'],
    decide_schedule_id: APIPaths['/app/conversations/{conversation_id}/schedule-accept/{decide_schedule_id}']['put']['parameters']['path']['decide_schedule_id'],
  ): Promise<
    APIPaths['/app/conversations/{conversation_id}/schedule-accept/{decide_schedule_id}']['put']['responses']['200']['content']['application/json']
  > {
    return (await this.put(`/app/conversations/${conversation_id}/schedule-accept/${decide_schedule_id}`)).data
  }
  async appPutConversationComplete(
    conversation_id: APIPaths['/app/conversations/{conversation_id}/complete']['put']['parameters']['path']['conversation_id'],
  ): Promise<
    APIPaths['/app/conversations/{conversation_id}/complete']['put']['responses']['200']['content']['application/json']
  > {
    return (await this.put(`/app/conversations/${conversation_id}/complete`)).data
  }
}
