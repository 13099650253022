import { loadingAtom } from '@/global-state/jotai-atoms'
import { appColor } from '@/styles/app-colors'
import { Grid, css } from '@mui/material'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import BackdropLoading from './common/BackdropLoading'
import AppFooter from './layout/AppFooter'
import AppNavbar from './layout/AppNavbar'
import MyChatRoomsProvider from './provider/MyChatRoomsProvider'
import RouterBackProvider from './provider/RouterBackProvider'

type Props = {
  noHeader?: boolean
  children: React.ReactNode
}

const Layout: React.FC<Props> = (props) => {
  const { noHeader, children } = props
  const router = useRouter()
  const isAuthPage = router.pathname.includes('auth')
  const [loading] = useAtom(loadingAtom)

  return (
    <main>
      {loading ? <BackdropLoading /> : null}
      <ToastContainer position="top-center" theme="colored" />
      {noHeader || isAuthPage ? (
        <Grid css={containerStyled(isAuthPage)}>{children}</Grid>
      ) : (
        <MyChatRoomsProvider>
          <RouterBackProvider>
            <AppNavbar />
            <Grid css={containerStyled(isAuthPage)}>{children}</Grid>
            <AppFooter />
          </RouterBackProvider>
        </MyChatRoomsProvider>
      )}
    </main>
  )
}

export default React.memo(Layout)

const containerStyled = (isAuthPage: boolean) => css`
  padding-top: ${isAuthPage ? 0 : '56px'};
  padding-bottom: ${isAuthPage ? 0 : '72px'};
  background-color: ${isAuthPage ? 'transparent' : appColor.Background.secondary};
  min-height: 100vh;
`
