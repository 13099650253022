import { APIPaths } from '@/apis/api-config'
import BaseApi from '../base-api'

export class appUserApi extends BaseApi {
  async appPostSignup(
    requestBody: APIPaths['/app/users/signup']['post']['requestBody']['content']['application/json'],
  ): Promise<APIPaths['/app/users/signup']['post']['responses']['200']['content']['application/json']> {
    return (await this.post('/app/users/signup', requestBody)).data
  }
  async appGetMe(
    queryParams?: APIPaths['/app/users/me']['get']['parameters']['query'],
  ): Promise<APIPaths['/app/users/me']['get']['responses']['200']['content']['application/json']> {
    return (await this.get('/app/users/me', { params: queryParams })).data
  }
  async appPutMe(
    requestBody: APIPaths['/app/users/me']['put']['requestBody']['content']['application/json'],
    queryParams?: APIPaths['/app/users/me']['put']['parameters']['query'],
  ): Promise<APIPaths['/app/users/me']['put']['responses']['200']['content']['application/json']> {
    return (await this.put('/app/users/me', requestBody, { params: queryParams })).data
  }
  async appDeleteMe(): Promise<void> {
    await this.delete('/app/users/me')
  }
  async appGetUserList(
    queryParams?: APIPaths['/app/users/list']['get']['parameters']['query'],
  ): Promise<APIPaths['/app/users/list']['get']['responses']['200']['content']['application/json']> {
    return (await this.get('/app/users/list', { params: queryParams })).data
  }
  async appGetUserByLineUserId(
    target_user_id: APIPaths['/app/users/{target_user_id}']['get']['parameters']['path']['target_user_id'],
    queryParams?: APIPaths['/app/users/{target_user_id}']['get']['parameters']['query'],
  ): Promise<APIPaths['/app/users/{target_user_id}']['get']['responses']['200']['content']['application/json']> {
    return (await this.get(`/app/users/${target_user_id}`, { params: queryParams })).data
  }
  async appPostFavoriteToLineUserId(
    target_user_id: APIPaths['/app/users/{target_user_id}/favorite']['post']['parameters']['path']['target_user_id'],
  ): Promise<
    APIPaths['/app/users/{target_user_id}/favorite']['post']['responses']['200']['content']['application/json']
  > {
    return (await this.post(`/app/users/${target_user_id}/favorite`)).data
  }
  async appDeleteFavoriteToLineUserId(
    target_user_id: APIPaths['/app/users/{target_user_id}/favorite']['delete']['parameters']['path']['target_user_id'],
  ): Promise<
    APIPaths['/app/users/{target_user_id}/favorite']['delete']['responses']['200']['content']['application/json']
  > {
    return (await this.delete(`/app/users/${target_user_id}/favorite`)).data
  }
}
