import { authUserAtom, idTokenAtom } from '@/global-state/jotai-atoms'
import liff from '@line/liff'
import { useAtom } from 'jotai'

export const useAuthentication = () => {
  const [idToken, setIdToken] = useAtom(idTokenAtom)
  const [authUser, setAuthUser] = useAtom(authUserAtom)

  const authInitialized = async (idToken: string) => {
    //
  }

  const authSync = async () => {
    //
  }

  const authLogout = async () => {
    try {
      await liff.ready
      await liff.logout()
      setIdToken('')
      setAuthUser(null)
      window.location.reload()
    } catch (error) {
      console.error('error', error)
    }
  }

  return {
    authLogout,
  }
}
