import { useMemo } from 'react'

import { appConversationApi } from '@/apis/app/conversation-api'
import { appExConnectApi } from '@/apis/app/ex-connect-api'
import { appTicketApi } from '@/apis/app/ticket-api'
import { appUserApi } from '@/apis/app/user-api'
import { idTokenAtom } from '@/global-state/jotai-atoms'
import { useAtom } from 'jotai'

export const useAppApi = () => {
  const [idToken] = useAtom(idTokenAtom)

  const userApi = useMemo(() => new appUserApi(idToken), [idToken])
  const conversationApi = useMemo(() => new appConversationApi(idToken), [idToken])
  const ticketApi = useMemo(() => new appTicketApi(idToken), [idToken])
  const exConnectApi = useMemo(() => new appExConnectApi(idToken), [idToken])

  return {
    userApi,
    conversationApi,
    ticketApi,
    exConnectApi,
  }
}
