import { ROOM_PATH } from '@/firestore/converter/RoomModelConverter'
import { useRoom } from '@/firestore/hooks/use-room'
import { authUserAtom } from '@/global-state/jotai-atoms'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import React from 'react'
import InitialScreenContainer from '../common/InitialScreenContainer'
type Props = {
  children: React.ReactNode
}

const MyChatRoomsProvider: React.FC<Props> = (props) => {
  const { children } = props
  const { getRoomByAuthId } = useRoom()
  const [authUser] = useAtom(authUserAtom)

  const result = useQuery({
    queryKey: [ROOM_PATH],
    queryFn: async () => await getRoomByAuthId(),
    enabled: !!authUser?.id,
  })

  if (result.isError || !authUser?.id || result.isLoading) {
    return <InitialScreenContainer />
  }

  return <>{children}</>
}

export default React.memo(MyChatRoomsProvider)
