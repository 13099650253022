import { jaJP } from '@mui/material/locale'
import { ThemeOptions, createTheme } from '@mui/material/styles'

import { appColor } from './app-colors'

// Material-UIパーツのテーマ
export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: appColor.App.purple,
    },
    secondary: {
      main: appColor.App.accent,
    },
    success: {
      main: appColor.Service.green,
    },
    error: {
      main: appColor.App.accent,
    },
    background: {
      default: appColor.App.white,
    },
    text: {
      primary: appColor.Text.primary,
      secondary: appColor.Text.secondary,
      disabled: appColor.Text.negative,
    },
    divider: appColor.Service.graySoft,
  },
}

export const muiTheme = createTheme(themeOptions, jaJP)
