import { authUserAtom, chatRoomListAtom, routerBackAtom } from '@/global-state/jotai-atoms'
import { appColor } from '@/styles/app-colors'
import { notojp } from '@/styles/app-typography'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Grid, IconButton, Toolbar, css } from '@mui/material'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import NavbarDrawer from './dialog/NavbarDrawer'

const AppNavbar: React.FC = () => {
  const router = useRouter()
  const [routerBack, setRouterBack] = useAtom(routerBackAtom)
  const [openMenu, setOpenMenu] = useState(false)
  const [authUser] = useAtom(authUserAtom)
  const [chatRooms] = useAtom(chatRoomListAtom)

  const unReadChatRoomCount =
    chatRooms?.filter((chatRoom) => {
      if (chatRoom?.targetUserId === authUser?.id) {
        if ((chatRoom?.responseUserViewedTime || 0) < (chatRoom?.targetUserSentTime || 0)) {
          return true
        }
      }
      if (chatRoom?.authUserId === authUser?.id) {
        if ((chatRoom.targetUserViewedTime || 0) < (chatRoom?.responseUserSentTime || 0)) {
          return true
        }
      }
    }).length || 0

  const onClickBack = async () => {
    if (routerBack) {
      setRouterBack('')
      await router.back()
    }
  }

  return (
    <>
      <AppBar css={appBarStyled}>
        <Toolbar>
          <Grid container justifyContent={'space-between'} alignContent={'center'}>
            {routerBack ? (
              <Grid item css={routerBackStyled}>
                <IconButton size={'medium'} onClick={onClickBack}>
                  <ArrowBackIosNewIcon />
                </IconButton>
              </Grid>
            ) : (
              <Grid item pt={0.5} onClick={() => window.location.reload()}>
                <img src="/icon/logo-white.svg" alt="" />
              </Grid>
            )}
            <Grid item css={titleStyled} pl={2}>
              Salons
            </Grid>
            {/* <Grid item>
              <IconButton color={'inherit'} size={'medium'} onClick={() => router.push('/request')}>
                <Badge badgeContent={unReadChatRoomCount} color="secondary">
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
            </Grid> */}
            <Grid item pl={1}>
              <IconButton color={'inherit'} size={'medium'} onClick={() => setOpenMenu(!openMenu)}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <NavbarDrawer open={openMenu} onClose={() => setOpenMenu(false)} />
    </>
  )
}
export default React.memo(AppNavbar)

const appBarStyled = css`
  max-width: 768px;
  position: fixed;
  background-color: ${appColor.Background.header};
  color: ${appColor.App.white};
  left: 50%;
  transform: translateX(-50%);
  & .MuiToolbar-root {
    min-height: 56px !important;
  }
`

const titleStyled = css`
  flex-grow: 1;
  font-family: ${notojp.style.fontFamily};
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: ${appColor.App.white};
`

const routerBackStyled = css`
  button {
    padding-left: 0;
  }
  svg {
    color: ${appColor.App.white};
  }
`
