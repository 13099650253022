import { notojp } from '@/styles/app-typography'
import ExtensionIcon from '@mui/icons-material/Extension'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import SettingsIcon from '@mui/icons-material/Settings'
import { BottomNavigation, BottomNavigationAction, Grid, Paper, css } from '@mui/material'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

const AppFooter: React.FC = () => {
  const router = useRouter()
  const [value, setValue] = useState<number>(0)

  useEffect(() => {
    const path = router.pathname
    switch (path) {
      case '/':
        setValue(0)
        break
      case '/request':
        setValue(1)
        break
      case '/ticket':
        setValue(2)
        break
      case '/setting':
        setValue(3)
        break
      default:
        break
    }
  }, [router.pathname])

  const onChangeNavigation = (newValue: number) => {
    setValue(newValue)
    switch (newValue) {
      case 0:
        router.push('/')
        break
      case 1:
        router.push('/request')
        break
      case 2:
        router.push('/ticket')
        break
      case 3:
        router.push('/setting')
        break
      default:
        break
    }
  }

  return (
    <Paper css={paperStyled} elevation={3}>
      <Grid container px={1} pb={2}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(_, newValue) => onChangeNavigation(newValue)}
          css={bottomNavStyled}
        >
          <BottomNavigationAction label="探す" icon={<PersonSearchIcon />} />
          <BottomNavigationAction label="リクエスト" icon={<ExtensionIcon />} />
          <BottomNavigationAction label="チケット" icon={<LocalActivityIcon />} />
          <BottomNavigationAction label="設定" icon={<SettingsIcon />} />
        </BottomNavigation>
      </Grid>
    </Paper>
  )
}
export default React.memo(AppFooter)

const paperStyled = css`
  position: fixed;
  max-width: 768px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`

const bottomNavStyled = css`
  width: 100%;
  svg, span {
    font-family: ${notojp.style.fontFamily};
    font-weight: 500;
  }
  span {
    padding-top: 4px;
  }
`
