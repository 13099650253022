import { loadingAtom } from '@/global-state/jotai-atoms'
import { appColor } from '@/styles/app-colors'
import { css } from '@emotion/react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useAtom } from 'jotai'
import * as React from 'react'

const BackdropLoading: React.FC = () => {
  const [loading] = useAtom(loadingAtom)

  return (
    <Backdrop css={backdropStyled} open={loading}>
      <CircularProgress color={'inherit'} />
    </Backdrop>
  )
}

export default React.memo(BackdropLoading)

const backdropStyled = css`
    z-index: 9999;
    color: ${appColor.App.white};
`
