import { useAppApi } from '@/custom-hooks/use-app-api'
import { useAppSnackbar } from '@/custom-hooks/use-app-snackbar'
import { authUserAtom, idTokenAtom } from '@/global-state/jotai-atoms'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import { Suspense, useEffect } from 'react'
import InitialScreenContainer from '../common/InitialScreenContainer'

type Props = {
  children: React.ReactNode
}

const AppAuthProvider: React.FC<Props> = (props) => {
  const { children } = props
  const router = useRouter()
  const isAuthPage = router.pathname.includes('auth')
  const { userApi } = useAppApi()
  const [authUser, setAuthUser] = useAtom(authUserAtom)
  const [idToken] = useAtom(idTokenAtom)
  const { setAppSnackbar } = useAppSnackbar()

  const result = useQuery({
    queryKey: ['/app/users/me'],
    queryFn: async () => await userApi.appGetMe(),
    enabled: !!idToken,
  })

  useEffect(() => {
    ;(async () => {
      if (result.data?.id) {
        if (isAuthPage) {
          await router.push('/')
        }
        setAppSnackbar('ログインに成功しました', { success: true })
        setAuthUser(result.data)
      }
    })()
  }, [result.data?.id])

  useEffect(() => {
    if (result.isError && !isAuthPage) {
      router.push('/auth/signup')
    }
  }, [result.isError])

  if (result.isError && !isAuthPage) {
    return <InitialScreenContainer />
  }
  if (result.isLoading) {
    return <InitialScreenContainer />
  }
  if (result.isSuccess && !authUser?.id) {
    return <InitialScreenContainer />
  }

  return <Suspense fallback={<InitialScreenContainer />}>{children}</Suspense>
}

export default AppAuthProvider
