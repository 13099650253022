import Layout from '@/components/Layout'
import AppSEO from '@/components/common/AppSEO'
import AppAuthProvider from '@/components/provider/AppAuthProvider'
import LineAuthProvider from '@/components/provider/LineAuthProvider'
import { _appStyled } from '@/styles/global-styled'
import { muiTheme } from '@/styles/mui-theme'
import { Global } from '@emotion/react'
import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider as JotaiProvider } from 'jotai'
import type { AppProps } from 'next/app'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  },
})

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <Global styles={_appStyled} />
        <AppSEO />
        <JotaiProvider>
          <LineAuthProvider>
            <AppAuthProvider>
              <Layout noHeader={!!pageProps.noAuth}>
                <Component {...pageProps} />
              </Layout>
            </AppAuthProvider>
          </LineAuthProvider>
        </JotaiProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default MyApp
