import { appColor } from '@/styles/app-colors'
import { css } from '@emotion/react'
import { Backdrop, CircularProgress, Grid } from '@mui/material'
import React from 'react'

const InitialScreenContainer: React.FC = () => {
  return (
    <Grid container css={overflowHidden}>
      <img src="/background/init-screen-wave-top.svg" alt="" css={waveTopStyled} />
      <img src="/background/init-screen-logo.png" alt="" css={logoStyled} />
      <img src="/background/init-screen-wave-bottom.svg" alt="" css={waveBottomStyled} />
      <Backdrop open={true} css={backdropStyled}>
        <CircularProgress color={'inherit'} />
      </Backdrop>
    </Grid>
  )
}
export default InitialScreenContainer

const overflowHidden = css`
  max-width: 768px;
  overflow: hidden;
  height: 100vh;
  z-index: 10000;
  position: absolute;
`

const waveTopStyled = css`
  position: absolute;
  top: -2px;
  width: 100%;
  z-index: -5;
`

const logoStyled = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 1;
`

const waveBottomStyled = css`
  position: absolute;
  bottom: -1px;
  width: 100%;
  z-index: -5;
`

const backdropStyled = css`
  z-index: 1000;
  color: ${appColor.App.white};
`
