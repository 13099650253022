export const appColor = {
  App: {
    primary: '#1F1429',
    purple: '#7332a0',
    accent: '#B33E5C',
    white: '#FFFFFF',
  },
  Service: {
    primary: '#1F1429',
    secondary: '#B33E5C',
    white: '#FFFFFF',
    purple: '#7332a0',
    graySoft: '#d4ced9',
    grayHard: '#8d7e9a',
    yellow: '#ae862b',
    blue: '#3a6dd7',
    pink: '#b558b3',
    red: '#b92935',
    orange: '#ea7717',
    green: '#00964c',
  },
  Text: {
    primary: '#08050a',
    secondary: '#4e4d4e',
    negative: '#cdcccd',
    linkBlue: '#4d8de8',
    white: '#FFFFFF',
  },
  Background: {
    primary: '#fdfdfd',
    secondary: '#F8F6F8',
    header: '#463666',
    navBar: '#EEECF0',
    orange: 'RGBA(234, 119, 23, 0.1)',
    green: 'RGBA(0, 150, 76, 0.1)',
  },
} as const
