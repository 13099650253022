import { renderToStaticMarkup } from "react-dom/server"


export const zeroPad = (num: number, length: number) => {
  return `${num}`.padStart(length, "0");
}

export const int = (v: any): number => (v && parseInt(v)) || 0

export const flattenObject = (obj: any, roots = [], sep = '.'): any =>
  Object.keys(obj).reduce(
    (memo, prop) =>
      Object.assign(
        {},
        memo,
        Object.prototype.toString.call(obj[prop]) === '[object Object]'
          ? flattenObject(obj[prop], roots.concat([prop as never]), sep)
          : { [roots.concat([prop as never]).join(sep)]: obj[prop] },
      ),
    {},
  )
  

export const createUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (a) => {
    const r = ((new Date().getTime() + Math.random() * 16) % 16) | 0
    const v = a === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const stringToColor = (str: string): string => {
  let hash = 0
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  return color
}

export const stringAvatarProps = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.slice(0, 1)}`,
  }
}

export const isIncompletePath = (path: string) => {
  const targetParams = ['[id]', '[type]', '[current_ws]', '[task_id]']
  return targetParams.some((p) => path.includes(p))
}

// カスタムdebounce関数
export const debounce = <T extends (...args: any[]) => any>(func: T, wait: number): T => {
  let timeout: NodeJS.Timeout | null
  // 引数で渡された関数(func)をデバウンス処理する新しい関数を返す
  return ((...args: Parameters<T>): void => {
    // 既存のタイムアウトをクリア
    if (timeout) {
      clearTimeout(timeout)
    }
    // 新しいタイムアウトを設定
    timeout = setTimeout(() => {
      func(...args)
    }, wait)
  }) as T
}

export const splitIgnoringQuotes = (str: string, delimiter: string): string[] => {
  const result: string[] = []
  let insideQuotes = false
  let currentWord = ''

  for (let i = 0; i < str.length; i++) {
    const char = str[i]

    if (char === '"') {
      insideQuotes = !insideQuotes
      currentWord += char
    } else if (char === delimiter && !insideQuotes) {
      result.push(currentWord)
      currentWord = ''
    } else {
      currentWord += char
    }
  }

  result.push(currentWord)

  return result
}

export const convertSvgToUri = (svg: React.ReactElement) => {
  const markup = renderToStaticMarkup(svg)
  const encoded = encodeURIComponent(markup)
  return `url('data:image/svg+xml;utf8,${encoded}')`
}

// 引数はbase64形式の文字列
export const toBlob = (base64: string): Blob | null => {
  const bin = atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);

  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }

  // Blobを作成
  try {
    const blob = new Blob([buffer.buffer], {
      type: "image/jpeg",
    });
    return blob;
  } catch (e) {
    return null;
  }
};
