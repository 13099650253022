import { APIPaths } from '@/apis/api-config'
import BaseApi from '../base-api'

export class appExConnectApi extends BaseApi {
  async appPostLineSendByUserId(
    userId: APIPaths['/app/ex-api/{target_line_user_id}/line-send']['post']['parameters']['path']['target_line_user_id'],
    requestBody: APIPaths['/app/ex-api/{target_line_user_id}/line-send']['post']['requestBody']['content']['application/json'],
  ): Promise<
    APIPaths['/app/ex-api/{target_line_user_id}/line-send']['post']['responses']['200']['content']['application/json']
  > {
    return (await this.post(`/app/ex-api/${userId}/line-send`, requestBody)).data
  }
}
