import { useAuthentication } from '@/custom-hooks/use-authentication'
import { idTokenAtom } from '@/global-state/jotai-atoms'
import liff from '@line/liff'
import { LiffMockPlugin } from '@line/liff-mock'
import { Alert, AlertTitle } from '@mui/material'
import { useAtom } from 'jotai'
import { Suspense, useEffect, useState } from 'react'
import InitialScreenContainer from '../common/InitialScreenContainer'

type Props = {
  children: React.ReactNode
}

const LineAuthProvider: React.FC<Props> = (props) => {
  const { children } = props
  const { authLogout } = useAuthentication()
  const [idToken, setIdToken] = useAtom(idTokenAtom)
  const [nowBrowser, setNowBrowser] = useState<string | null>(null)
  const [liffError, setLiffError] = useState<string | null>(null)

  const liffInit = () => {
    liff.use(new LiffMockPlugin())
    const liffId = process.env.APP_ENV === 'local' ? 'local' : process.env.NEXT_PUBLIC_LIFF_ID || ''
    liff
      .init({
        liffId: liffId,
        // @ts-ignore
        mock: process.env.APP_ENV === 'local',
      })
      .then(async () => {
        if (liff.isInClient()) {
          setNowBrowser('client')
        }
        if (process.env.APP_ENV === 'local') {
          setIdToken(process.env.NEXT_PUBLIC_LINE_USER_ID || 'local')
        }
        if (liff.isLoggedIn()) {
          const idToken = await liff.getIDToken()
          setIdToken(idToken || '')
        } else {
          liff.login()
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setLiffError(error.toString())
      })
  }

  useEffect(() => {
    liffInit()
  }, [])

  useEffect(() => {
    const timeout = setInterval(
      async () => {
        if (nowBrowser === 'client') {
          const idToken = await liff.getIDToken()
          setIdToken(idToken || '')
        } else {
          authLogout()
          window.location.reload()
        }
      },
      1000 * 60 * 20,
    )
    return () => clearTimeout(timeout)
  }, [idToken])

  if (liffError) {
    return (
      <Alert severity="warning">
        <AlertTitle>Error</AlertTitle>
        LINEとアプリの連携に失敗しました。
        <br />
        お手数ですが、再度アクセスしてください。
      </Alert>
    )
  }

  if (!idToken) {
    return <InitialScreenContainer />
  }

  return <Suspense fallback={<InitialScreenContainer />}>{children}</Suspense>
}

export default LineAuthProvider
