import { useAppSnackbar } from '@/custom-hooks/use-app-snackbar'
import { useAuthentication } from '@/custom-hooks/use-authentication'
import { appServiceLinkPath } from '@/global-state/external-path'
import { authUserAtom } from '@/global-state/jotai-atoms'
import { appColor } from '@/styles/app-colors'
import { notojp } from '@/styles/app-typography'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import ExtensionIcon from '@mui/icons-material/Extension'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import SettingsIcon from '@mui/icons-material/Settings'
import { Drawer, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, css } from '@mui/material'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import React from 'react'

type Props = {
  open: boolean
  onClose: () => void
}

const NavbarDrawer: React.FC<Props> = (props) => {
  const { open, onClose } = props
  const router = useRouter()
  const { setAppSnackbar } = useAppSnackbar()
  const { authLogout } = useAuthentication()
  const [authUser] = useAtom(authUserAtom)

  const onClickLogout = async () => {
    setAppSnackbar('ログアウトしました', {
      info: true,
    })
    await router.push('/')
    await authLogout()
  }

  const onClickListItem = async (path: string) => {
    await router.push(path)
    onClose()
  }

  return (
    <Drawer anchor={'right'} open={open} onClose={onClose} css={drawerStyled}>
      <Grid container direction={'column'} position={'relative'} css={containerStyled} py={3}>
        <Grid item px={2}>
          <img src="/icon/logo-with-name.svg" alt="" css={logoImageStyled} />
        </Grid>
        <Grid item pt={1}>
          <ListItem disablePadding css={listItemButtonStyled(['/', '/home'].includes(router.pathname))}>
            <ListItemButton onClick={() => onClickListItem('/home')}>
              <ListItemIcon>
                <PersonSearchIcon />
              </ListItemIcon>
              <ListItemText primary="ホーム" />
            </ListItemButton>
          </ListItem>
        </Grid>
        {/* <ListItem disablePadding css={listItemButtonStyled(router.pathname.includes('isFavorite'))}>
          <ListItemButton onClick={() => onClickListItem('/home/isFavorite')}>
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
            <ListItemText primary="お気に入り" />
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding css={listItemButtonStyled(router.pathname.includes('request'))}>
          <ListItemButton onClick={() => onClickListItem('/request')}>
            <ListItemIcon>
              <ExtensionIcon />
            </ListItemIcon>
            <ListItemText primary="リクエスト" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding css={listItemButtonStyled(router.pathname.includes('ticket'))}>
          <ListItemButton onClick={() => onClickListItem('/ticket')}>
            <ListItemIcon>
              <LocalActivityIcon />
            </ListItemIcon>
            <ListItemText primary="チケット" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding css={listItemButtonStyled(router.pathname.includes('setting'))}>
          <ListItemButton onClick={() => onClickListItem('/setting')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="設定" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding css={listItemButtonStyled(router.pathname.includes('contact'))}>
          <ListItemButton href={appServiceLinkPath(authUser?.profile?.name || '', '')} target="_blank">
            <ListItemIcon>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="お問い合わせ" />
          </ListItemButton>
        </ListItem>
        <Grid item css={logoutStyled} pb={6}>
          <ListItem disablePadding css={listItemButtonStyled(false)}>
            <ListItemButton onClick={onClickLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItemButton>
          </ListItem>
        </Grid>
      </Grid>
    </Drawer>
  )
}
export default React.memo(NavbarDrawer)

const drawerStyled = css`
  & .MuiPaper-root {
    background-color: ${appColor.Background.navBar};
    min-width: 256px;
  }
`

const containerStyled = css`
  height: 100%;
`

const logoImageStyled = css`
  width: 168px;
`

const logoutStyled = css`
  position: absolute;
  bottom: 0;
  width: 100%;
`
const listItemButtonStyled = (isPath: boolean) => css`
  & .MuiListItemButton-root {
    padding: 8px 20px;
  }
  & .MuiListItemIcon-root {
    min-width: 40px;
  }
  span {
    font-family: ${notojp.style.fontFamily};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${isPath ? appColor.App.purple : appColor.Text.secondary};
  }
  svg {
    color: ${isPath ? appColor.App.purple : appColor.Text.secondary};
  }
`
