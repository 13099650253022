import { APIPaths, APISchema } from '@/apis/api-config'
import { RoomType } from '@/firestore/converter/RoomModelConverter'
import { atom } from 'jotai'

export const loadingAtom = atom<boolean>(false)

// LINEのIDトークン(https://developers.line.biz/ja/reference/liff/#get-id-token)
export const idTokenAtom = atom<string>('')

// 自分自身のユーザー情報
export const authUserAtom = atom<APISchema['User'] | null>(null)

// router backのためのatom
export const routerBackAtom = atom<string>('')

export const userListQueryParamsAtom = atom<APIPaths['/app/users/list']['get']['parameters']['query']>({
  limit: 1000,
})

// やりとり一覧のステータス
// "NONE" | "REQUEST" | "APPROVE" | "REJECT" | "CANCEL" | "CHECK_SCHEDULE" | "SCHEDULED" | "COMPLETE";
export const conversationListQueryParamsAtom = atom<
  APIPaths['/app/conversations/me/list']['get']['parameters']['query']
>({
  limit: 1000,
  query_is_status: 'APPROVE,REJECT,CANCEL,CHECK_SCHEDULE,SCHEDULED,COMPLETE',
})

// 自分の持つChatRoomの一覧
export const chatRoomListAtom = atom<RoomType[]>([])

// 今いるチャットルームのID
export const currentConversationIdAtom = atom<string>('')
