import { authUserAtom, chatRoomListAtom, loadingAtom } from '@/global-state/jotai-atoms'
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore'
import { useAtom, useSetAtom } from 'jotai'
import { ROOM_PATH, RoomType, roomModelConverter } from '../converter/RoomModelConverter'
import { firestoreDB } from '../firebase-utils'

export const useRoom = () => {
  const [authUser] = useAtom(authUserAtom)
  const setChatRooms = useSetAtom(chatRoomListAtom)
  const setLoading = useSetAtom(loadingAtom)

  const addRoom = async (room: RoomType) => {
    setLoading(true)
    try {
      const docRef = doc(firestoreDB, ROOM_PATH, room.conversationId).withConverter(roomModelConverter)
      await setDoc(docRef, room)
    } finally {
      setLoading(false)
    }
  }

  const getRoomByAuthId = async () => {
    setLoading(true)
    try {
      if (!authUser) return
      const collRef = collection(firestoreDB, `/${ROOM_PATH}`).withConverter(roomModelConverter)

      const authUserQ = query(collRef, where('authUserId', '==', authUser?.id))
      const authUserSnapshot = await getDocs(authUserQ)
      const authUserResults = authUserSnapshot.docs.map((doc) => doc.data())

      const targetUserQ = query(collRef, where('targetUserId', '==', authUser?.id))
      const targetUserSnapshot = await getDocs(targetUserQ)
      const targetUserResults = targetUserSnapshot.docs.map((doc) => doc.data())

      setChatRooms([...authUserResults, ...targetUserResults])
      return [...authUserResults, ...targetUserResults]
    } finally {
      setLoading(false)
    }
  }

  const getRoomById = async (conversationId: string) => {
    setLoading(true)
    try {
      const docRef = doc(firestoreDB, `/${ROOM_PATH}`, conversationId).withConverter(roomModelConverter)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        return docSnap.data()
      } else {
        return null
      }
    } finally {
      setLoading(false)
    }
  }

  return {
    addRoom,
    getRoomByAuthId,
    getRoomById,
  }
}
