import { routerBackAtom } from '@/global-state/jotai-atoms'
import { useSetAtom } from 'jotai'
import { useRouter } from 'next/router'
import React from 'react'
import { useEffect } from 'react'

type Props = {
  children: React.ReactNode
}

const RouterBackProvider: React.FC<Props> = (props) => {
  const { children } = props
  const router = useRouter()
  const setRouterBack = useSetAtom(routerBackAtom)

  useEffect(() => {
    if (router.asPath.includes('setting') && router.asPath.split('/')[2]) {
      return setRouterBack('/setting')
    }
    if (router.asPath.includes('home') && router.asPath.split('/')[2]) {
      return setRouterBack('/home')
    }
    if (router.asPath.includes('request') && router.asPath.split('/')[2]) {
      return setRouterBack('/request')
    }
    return setRouterBack('')
  }, [router.asPath])

  return <>{children}</>
}

export default React.memo(RouterBackProvider)
