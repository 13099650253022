import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore'

export const ROOM_PATH = `env/${process.env.APP_ENV}/rooms`

export type RoomType = {
  id: string
  conversationId: string
  authUserId: string
  targetUserId: string
  latestMessage: string
  responseUserViewedTime: number | null
  targetUserViewedTime: number | null
  responseUserSentTime: number | null
  targetUserSentTime: number | null
  createTime: number
  updateTime: number
}

export const roomModelConverter: FirestoreDataConverter<RoomType> = {
  toFirestore(room: RoomType): DocumentData {
    return {
      id: room.id,
      conversationId: room.conversationId,
      authUserId: room.authUserId,
      targetUserId: room.targetUserId,
      latestMessage: room.latestMessage,
      responseUserViewedTime: Math.round(room.responseUserViewedTime || 0),
      targetUserViewedTime: Math.round(room.targetUserViewedTime || 0),
      responseUserSentTime: Math.round(room.responseUserSentTime || 0),
      targetUserSentTime: Math.round(room.targetUserSentTime || 0),
      createTime: Math.round(room.createTime || 0),
      updateTime: Math.round(room.updateTime || 0),
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): RoomType {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      conversationId: data.conversationId,
      authUserId: data.authUserId,
      targetUserId: data.targetUserId,
      latestMessage: data.latestMessage,
      responseUserViewedTime: Math.round(data.responseUserViewedTime || 0),
      targetUserViewedTime: Math.round(data.targetUserViewedTime || 0),
      responseUserSentTime: Math.round(data.responseUserSentTime || 0),
      targetUserSentTime: Math.round(data.targetUserSentTime || 0),
      createTime: Math.round(data.createTime || 0),
      updateTime: Math.round(data.updateTime || 0),
    }
  },
}
