import React from 'react'

import { DefaultSeo } from 'next-seo'

const AppSEO: React.FC = () => {
  return (
    <DefaultSeo
      defaultTitle={'Salons'}
      canonical={'https://salons-app.com/'}
      description={'Salonsへようこそ'}
      twitter={{
        handle: 'Salons_app',
        site: 'Salons_app',
        cardType: 'summary',
      }}
      openGraph={{
        type: 'website',
        title: 'Salons',
        description: 'Salonsへようこそ',
        site_name: 'Salons',
        url: 'https://salons-app.com/',
        images: [
          {
            url: 'https://ffebd89d1fdc176971a9dc0e7d64eca332998f463481b44ee12d8f0-apidata.googleusercontent.com/download/storage/v1/b/ogp-for-hinome-pj-prd/o/service-ogp-img.png?jk=AYvHSREcmKIQ-6nxIuz5WBpuo7oRiz7QFCWQI7QF3UVxnQG4os7WoLUH8tGjMhrSVEO9ITy79FQQQL97RJkwAr4cr2dQtQrGS8mLKn0e_oykVj4B3W_5NqmTZUk8fK_NWkhb9-5JuJ7QOQUoxapg05_ceifK59h3L2dILrzbwuvn-e4JscoYXCGBx02hRLVlcr3vVX6RbT3rXbJp8RTla2_MyS-8jQr1bFyqONAN71ECDHHj3H3P-QU6ZnYk2kn6RJ-pcCP57bbgC3Ey1jBOsYyp5GWLxDfbLwYToJNDAIoS-C1hqINa8rnfYP3f09CGmxpNSw5lVIFuCt0PhMi9M6p3Ygq6FfUMhpfvZMX8Q3tIfsYi-ViSpy457xfr55BW-0TqjsbsoipII71z4ghYOIT5OdFuCxHiNYysyGeX5I983kBK9YqbkhNeISxsVAmozSlCVdbsApyoryLxxtFDS-OOH-9hRj5Y8CqRg_SWobtU37Iw92lrvg9YddZzym8GO-pvduV6Pf8zRJe2IwcTMpCg1Tq0rnw0tRu6f0HkonogKmbgoxwhbgC1MKaSqDC-Xb2Akkvq1bBc8LsuL30d5LIr76UGzOfU2ptSbuetQK9kpCGS67QpO-1lF6ek_9Rox5XBy8TQeu9PnActOm6z1TyI__bn0crVZExfkk6oUm38j0dmv79lpQzRfNc31lirAzsXs26P5fL29YAX-39nOjJbND2fbgxe74pBsH2hxlfuA9V1-prokcpd9PiBVz81Ot-sdqPTBS3wmWu0Upm-AOHkBRDMBlmgIe8olWfcXSX2ypCIhBFv202NjGu-4A7w9j0VX4PmsL_PDdS7mI2zxHuyFAM3LHjLYAcHHCc9cE3cSGHAWGn8d893MbqW3gYESo3Y-AsbDgyfJj0khGIZXOA1aVbCFpcY2hy2qRByVubZaB53LlsPlOFEab8q4bX3v3KbxHT1F8WZdM66IxMg-V3Qi-WDAZFUe8r4wB_MYGOZML_eG2V3rFQazBM0pvQfQvqjIKSBKdLihJwl6jKus_UEmqRN8MUaFFDMv9T6_7fBjW5DnoZfEgt34s1H5kCuP5FXBQCDldB8n98HdB_WJwW3121oPBPsKoasDFzC-h0SaoaXJ5lETJMQMyKoEirnLrnjfw4madaDrYCIB77igmGv4A6yXUDuOxhNo9Yv3shW87-6Ll1PhFAKzBtuxckJAYYXU69bYUV4x5CL8RJlkSkZazwb6r5pwThWSybs3UEpVv90idpxP0E&isca=1',
            width: 800,
            height: 600,
            alt: 'Salons(サロンズ)へようこそ',
          },
        ],
      }}
    />
  )
}
export default React.memo(AppSEO)
