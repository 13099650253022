import { APIPaths } from '@/apis/api-config'
import BaseApi from '../base-api'

export class appTicketApi extends BaseApi {
  async appGetTicketMe(
    queryParams?: APIPaths['/app/tickets/me']['get']['parameters']['query'],
  ): Promise<APIPaths['/app/tickets/me']['get']['responses']['200']['content']['application/json']> {
    return (await this.get('/app/tickets/me', { params: queryParams })).data
  }
  async appPutTicketIdUse(
    ticket_id: APIPaths['/app/tickets/{ticket_id}/use']['put']['parameters']['path']['ticket_id'],
  ): Promise<APIPaths['/app/tickets/{ticket_id}/use']['put']['responses']['200']['content']['application/json']> {
    return (await this.put(`/app/tickets/${ticket_id}/use`)).data
  }
}
