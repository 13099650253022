import { ReactNode, useCallback } from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'

import { createUUID } from '@/utils/common-utils'

import UndoAppSnackbarMessage from '@/components/common/UndoAppSnackbarMessage'
import { toast } from 'react-toastify'

export const useAppSnackbar = () => {
  const setAppSnackbar = useCallback(
    (
      message: string,
      opts?: {
        info?: boolean
        success?: boolean
        error?: boolean
        warn?: boolean
        autoCloseMSec?: number // 閉じるまでの時間（ミリ秒） -> デフォルトは3秒で閉じる
        delayMSec?: number // 遅延実行（ミリ秒）
        messageId?: string // 識別ID（途中で表示しているメッセージを変更する場合に利用）
        undoAction?: () => void //「元に戻す」アクション
      },
    ) => {
      const messageId = opts?.messageId || createUUID()
      const options = {
        autoClose: opts?.autoCloseMSec || opts?.undoAction ? 5000 : 3000,
        delay: opts?.delayMSec,
        toastId: messageId,
      }
      const toastNode: ReactNode = opts?.undoAction ? (
        <UndoAppSnackbarMessage messageId={messageId} message={message} onClick={opts.undoAction} />
      ) : (
        <>{message}</>
      )
      if (opts?.info) {
        return toast.info(toastNode, {
          ...options,
          icon: <InfoIcon />,
        })
      } else if (opts?.success) {
        return toast.success(toastNode, {
          ...options,
          icon: <CheckCircleIcon />,
        })
      } else if (opts?.error) {
        return toast.error(toastNode, {
          ...options,
          icon: <ErrorIcon />,
        })
      } else if (opts?.warn) {
        return toast.warn(toastNode, {
          ...options,
          icon: <WarningIcon />,
        })
      }
      toast(toastNode, options)
    },
    [],
  )

  return {
    setAppSnackbar: setAppSnackbar,
  }
}
